"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
Object.defineProperty(exports, "__esModule", { value: true });
exports.withdrawNfts = exports.getNfts = exports.MAX_AGE = exports.MIN_AGE = exports.boostCard = exports.getNFTBurnerCards = exports.MAX_LIMIT = exports.updateSale = exports.createSale = exports.transferNft = exports.NFT = void 0;
var pagination_1 = require("../../pagination");
var athlete_1 = require("../../soccer-data/athlete");
var sport_1 = require("../../soccer-data/sport");
var technical_1 = require("../../technical");
var token_1 = require("../token");
var validation_1 = require("../validation");
var conditionBonus_1 = require("./conditionBonus");
var endpoint_1 = require("./endpoint");
var type_1 = require("./type");
exports.NFT = {
    numericality: {
        onlyInteger: true,
        greaterThan: 0,
    },
    presence: {
        allowEmpty: false,
    },
};
exports.transferNft = {
    blockchain: validation_1.blockchain,
    to: technical_1.address,
    nft: exports.NFT,
};
exports.createSale = {
    blockchain: validation_1.blockchain,
    weiPrice: token_1.weiAmount,
};
exports.updateSale = {
    blockchain: validation_1.blockchain,
    weiPrice: token_1.weiAmountOptional,
};
exports.MAX_LIMIT = 16;
exports.getNFTBurnerCards = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.MAX_LIMIT }),
    },
    include: {
        array: (_a = {},
            _a[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _a.format = technical_1.id.format,
            _a),
    },
    exclude: {
        array: (_b = {},
            _b[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _b.format = technical_1.id.format,
            _b),
    },
    sport: sport_1.SportParameter,
};
exports.boostCard = {
    burn: {
        array: (_c = {},
            _c[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _c.numericality = {
                onlyInteger: true,
                greaterThan: 0,
            },
            _c),
    },
};
exports.MIN_AGE = 0;
exports.MAX_AGE = 200;
exports.getNfts = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.MAX_LIMIT }),
    },
    sort: {
        inclusion: (0, technical_1.enumToArray)(endpoint_1.NftsSort),
    },
    owner: {
        array: (_d = {},
            _d[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _d.format = technical_1.id.format,
            _d),
    },
    athlete: {
        array: (_e = {},
            _e[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _e.format = technical_1.id.format,
            _e),
    },
    'age.$gte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_AGE,
            lessThanOrEqualTo: exports.MAX_AGE,
        },
    },
    'age.$lte': {
        numericality: {
            greaterThanOrEqualTo: exports.MIN_AGE,
            lessThanOrEqualTo: exports.MAX_AGE,
        },
    },
    position: {
        array: (_f = {},
            _f[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _f.inclusion = athlete_1.ALL_POSITIONS,
            _f),
    },
    country: {
        array: (_g = {},
            _g[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _g.format = technical_1.id.format,
            _g),
    },
    club: {
        array: (_h = {},
            _h[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _h.format = technical_1.id.format,
            _h),
    },
    season: {
        array: (_j = {},
            _j[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _j.format = technical_1.id.format,
            _j),
    },
    competition: {
        array: (_k = {},
            _k[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _k.format = technical_1.id.format,
            _k),
    },
    level: {
        array: (_l = {},
            _l[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _l.inclusion = (0, technical_1.enumToArray)(type_1.RarityLevel, false),
            _l),
    },
    condition: {
        array: (_m = {},
            _m[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _m.inclusion = (0, technical_1.enumToArray)(conditionBonus_1.ConditionBonus),
            _m),
    },
    sport: sport_1.SportParameter,
};
exports.withdrawNfts = {
    blockchain: validation_1.blockchain,
    to: technical_1.address,
    nfts: {
        array: (_o = {},
            _o[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _o.format = technical_1.address,
            _o),
    },
};
