"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUEUE = void 0;
var QUEUE;
(function (QUEUE) {
    QUEUE["PREPARE_ATHLETE_RARITY"] = "PREPARE_ATHLETE_RARITY";
    QUEUE["CREATE_WALLET"] = "CREATE_WALLET";
    QUEUE["MINT"] = "MINT";
    QUEUE["TRANSFER_TOKEN"] = "TRANSFER_TOKEN";
    QUEUE["TRANSFER_TOKEN_ERROR"] = "TRANSFER_TOKEN_ERROR";
    QUEUE["TRANSFER_NFT"] = "TRANSFER_NFT";
    QUEUE["TRANSFER_ALL_NFT"] = "TRANSFER_ALL_NFT";
    QUEUE["CREATE_SALE"] = "CREATE_SALE";
    QUEUE["CREATE_SALE_ERROR"] = "CREATE_SALE_ERROR";
    QUEUE["UPDATE_SALE"] = "UPDATE_SALE";
    QUEUE["UPDATE_SALE_ERROR"] = "UPDATE_SALE_ERROR";
    QUEUE["DESTROY_SALE"] = "DESTROY_SALE";
    QUEUE["DESTROY_SALE_ERROR"] = "DESTROY_SALE_ERROR";
    QUEUE["ACCEPT_SALE"] = "ACCEPT_SALE";
    QUEUE["ACCEPT_SALE_ERROR"] = "ACCEPT_SALE_ERROR";
    QUEUE["MARKET_BUY"] = "MARKET_BUY";
    QUEUE["MARKET_BUY_ERROR"] = "MARKET_BUY_ERROR";
    QUEUE["TOKEN_PAYMENT"] = "TOKEN_PAYMENT";
    QUEUE["TOKEN_PAYMENT_REFUND"] = "TOKEN_PAYMENT_REFUND";
    QUEUE["TOKEN_PAYMENT_EXECUTE"] = "TOKEN_PAYMENT_EXECUTE";
    QUEUE["TOKEN_PAYMENT_ERROR"] = "TOKEN_PAYMENT_ERROR";
    QUEUE["TOKEN_PAYMENT_SUCCESS"] = "TOKEN_PAYMENT_SUCCESS";
    QUEUE["TOKEN_PAYMENT_FAILURE"] = "TOKEN_PAYMENT_FAILURE";
    QUEUE["TOKEN_PAYMENT_REFUND_FAILURE"] = "TOKEN_PAYMENT_REFUND_FAILURE";
    QUEUE["TOKEN_PAYMENT_EXECUTE_FAILURE"] = "TOKEN_PAYMENT_EXECUTE_FAILURE";
    QUEUE["SALE_SUCCESS"] = "SALE_SUCCESS";
    QUEUE["SALE_FAILURE"] = "SALE_FAILURE";
    QUEUE["DISTRIBUTE"] = "DISTRIBUTE";
    QUEUE["DISTRIBUTE_OFFCHAIN"] = "DISTRIBUTE_OFFCHAIN";
    QUEUE["DISTRIBUTE_ERROR"] = "DISTRIBUTE_ERROR";
    QUEUE["PREPARE_MINT"] = "PREPARE_MINT";
    QUEUE["MARKETPLACE_NFT_SOLD"] = "MARKETPLACE_NFT_SOLD";
    QUEUE["GAMEWEEK_STARTS_IN_2H"] = "GAMEWEEK_STARTS_IN_2H";
    QUEUE["NOTIFY_TECH_BY_EMAIL"] = "NOTIFY_TECH_BY_EMAIL";
    QUEUE["AUCTION_WIN_EMAIL"] = "AUCTION_WIN_EMAIL";
    QUEUE["AUCTION_OUTBID_EMAIL"] = "AUCTION_OUTBID_EMAIL";
    QUEUE["AUCTION_START_EMAIL"] = "AUCTION_START_EMAIL";
    QUEUE["CREATE_PACK_PRIZE"] = "CREATE_PACK_PRIZE";
    QUEUE["REWARD_RECEIVED_EMAIL"] = "REWARD_RECEIVED_EMAIL";
    QUEUE["WALLET_BALANCE_CHECK"] = "WALLET_BALANCE_CHECK";
    QUEUE["WALLET_BALANCE_REFILL"] = "WALLET_BALANCE_REFILL";
    QUEUE["DAILY_VISIT"] = "DAILY_VISIT";
})(QUEUE || (exports.QUEUE = QUEUE = {}));
